
<template>
    <div class="wrap" :style="{'background': background}" :class="{'fixed':isFloat}">
        <div class="bg" :class="{'active':isBg}">
            <div class="box" :style="{'width': width}">
                <slot></slot>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name:'FloorView',
    props: {
        width: {
            type: String,
            default: '1400px'
        },
        background: {
            type: String,
            default: '#ffffff'
        },
        isFloat:{
            type:Boolean,
            default:false
        },
        isBg:{
            type:Boolean,
            default:false
        }
    }
}
</script>

<style lang="scss" scoped>
.wrap{
    width: 100%;
    .bg{
        width: 100%;
        &.active{
            background: url('../assets/sys_bg.png');
            background-position: bottom;
            background-repeat: no-repeat;
            // background-size: 100%;
        }
    }
    .box{
        width: 1400px;
        margin: 0 auto;
        // border: 1px solid red;
    }
}
.fixed{
    position: fixed;
    top: 0;
}
</style>
