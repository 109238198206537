
<template>
  <div>
    <!-- head -->
    <FloorView :background="'rgba(30,30,50,0.5)'" width="1343px" :isFloat="true">
      <div class="head flex_row_between">
        <div class="sj_icon flex_row">
          <img src="../assets/icon.png">
          <div class="icon_line"></div>
          <div>长数链</div>
        </div>
        <div class="header_tab flex_row">
          <div class="tab_item" @click="active = 0">
            <a :href="list[0].label" :class="{'active':active == 0}">关于我们<span></span></a>
          </div>
          <div class="tab_item" @click="active = 1">
            <a :href="list[1].label" :class="{'active':active == 1}">平台优势<span></span></a>
          </div>
          <div class="tab_item" @click="active = 2">
            <a :href="list[2].label" :class="{'active':active == 2}">产品功能<span></span></a>
          </div>
          <div class="tab_item last_item" @click="active = 3">
            <a :href="list[3].label" :class="{'active':active == 3}">系统架构<span></span></a>
          </div>
        </div>
      </div>
    </FloorView>
    <FloorView :background="'#1e1e32'">
      <div style="height:100px;"></div>
    </FloorView>
    <!-- 关于我们 -->
    <FloorView :background="'#1e1e32'">
      <div class="content flex_row_center" :id="list[0].id">
        <img class="image" src="../assets/model_first.png" alt="">
      </div>
    </FloorView>
    <!-- 平台优势 -->
    <FloorView :background="'#f7f7f7'">
      <div class="content flex_column_center second_box" :id="list[1].id">
        <div class="center_title">
          <div>平台优势</div>
          <div class="title_line
          
          "></div>
        </div>
        <img class="image" src="../assets/model_pingtai.png">
      </div>
    </FloorView>
    <!-- 产品功能 -->
    <FloorView :background="'#EEEEEF'">
      <div class="content flex_column_center third_box" :id="list[2].id">
        <div class="center_title">
          <div>产品功能</div>
          <div class="title_line"></div>
        </div>
        <img class="image third_img" src="../assets/model_chanpin.png">
      </div>
    </FloorView>
    <!-- 系统架构 -->
    <FloorView :background="'#f7f7f7'" :isBg="true">
      <div class="content flex_column_center fourth_box" :id="list[3].id">
        <div class="center_title">
          <div>系统架构</div>
          <div class="title_line"></div>
        </div>
        <img class="image" src="../assets/model_xitong.png">
      </div>
    </FloorView>
    <!-- footer -->
    <FloorView :background="'#1e1e32'" width="1343px">
      <div class="footer">
         <div class="left">
          <div class="h1">友情链接</div>
          <div class="remark">
            <a href="http://www.dcps.top">数公金服</a>
          </div>
        </div>
        <div class="center">
          <div class="h1">联系我们</div>
          <div class="remark">
            <div class="flex_row_center"><img class="icons" src="../assets/phone.png"><div>0532-55772222</div></div>
            <div class="flex_row_center">
              <img class="icons" src="../assets/location.png">
              <div>山东省青岛市崂山区苗岭路15号GVCC全球（青岛）创投中心11楼</div>
            </div>
            <div>Copyright © 2022 数金公共服务（青岛）有限公司 | 鲁ICP备2021046348号-1</div>
          </div>
        </div>
        <div class="right">
          <img src="../assets/search.png">
          <input type="text" placeholder="请输入关键字">
        </div>
      </div>
    </FloorView>
  </div>
</template>
<script>

import FloorView from '@/components/FloorView.vue'
export default {
  name:'HomeView',
  components: {  FloorView },
  data(){
    return{
      active:0,
      list:[
        {id:'me',label:'#me'},
        {id:'pingtai',label:'#pingtai'},
        {id:'chanpin',label:'#chanpin'},
        {id:'xitong',label:'#xitong'},
      ]
    }
  },
  methods:{
    doThat(){

    }
  }
}
</script>
<style lang="scss" scoped>
.head{
  height: 100px;//改
  .sj_icon{
    color: #E3CF95;
    font-weight: 600;
    font-size: 30px;//改
    font-family: '黑体';
    .icon_line{
      height: 26px;//改
      width: 2px;//改
      border-radius: 50px;
      margin:0 16px;//改
      background: #e2ce94;
    }
    img{
      width: 123px;//改
      height: 40px;//改
    }
  }
  .header_tab{
    color: #fff;
    font-size: 20px;//改
    a{
      color: #fff;
      text-decoration: none;
      &.active{
        color: #FEC576;
        position:relative;
        span{
          width: 29px;//改
          height: 2px;//改
          border-radius: 8px;//改
          position: absolute;
          left: 0;
          bottom: -4px;//改
          background: #FEC576;
        }
      }
    }
    a:hover{
      position:relative;
      color: #FEC576;
      span{
        width: 29px;//改
        height: 2px;//改
        border-radius: 8px;//改
        position: absolute;
        left: 0;
        bottom: -4px;//改
        background: #FEC576;
      }
    }
    .tab_item{
      margin-right: 84px;//改
      font-weight: 600;
      font-family: '黑体';
      &.last_item{
        margin-right: 0;
      }
    }
  }
}
.content{
  height: 765px;
  .imgage{
    width: 1400px;
    height: 760px;
  }
  &.second_box{
    height: 960px;
    .image{
      width: 1304px;
      height: 636px;
      margin-top: 55px;
    }
  }
  &.third_box{
    height: 1077px;
    .image{
      width: 1318px;
      height: 763px;
      margin-top: 50px;
    }
  }
  &.fourth_box{
    height: 1063px;
    .image{
      width: 1317px;
      height: 745px;
      margin-top: 50px;
    }
  }
  
  .center_title{
    font-size: 48px;//改
    color: #252538;
    padding-top: 45px;//改
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title_line{
      margin-top: 2px;
      width: 67px;//改
      height: 5px;//改
      background: #FEC576;
      border-radius: 50px;
    }
  }
}
.footer{
  height: 240px;
  display: flex;
  .left{
      margin-right: 167px;
      .h1{
        font-size: 22px;
        font-weight: 500;
        color: #DBDCDC;
        margin-top: 30px;
      }
      .remark{
        margin-top: 39px;
        font-size: 14px;
        font-weight: 400;
        color: #9E9E9F;
        text-align: left;
      }
    }
    .center{
      flex:1;

      .h1{
        font-size: 22px;
        font-weight: 500;
        color: #DBDCDC;
        margin-top: 30px;
        text-align: left;
      }
      .remark{
        margin-top: 39px;
        font-size: 14px;
        font-weight: 400;
        color: #9E9E9F;
        text-align: left;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .icons{
          width: 15px;
          height: 15px;
          margin-right: 5px;
          margin-bottom: 2px;
        }
      }
    }
    .right{
      position: relative;
      input{
        position: absolute;
        left: 5px;
        top: 36px;
        width: 246px;
        height: 30px;
        padding: 5px;
        background: rgba(255,255,255,0);
        outline: none;
        color:#fff;
        border:0;
      }

      img{
        margin-top: 30px;
        width: 299px;
        height: 40px;
      }
    }
    a{
      color: #9E9E9F;
      text-decoration: none;
    }
}
</style>