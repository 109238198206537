/*
 * @Author: stella_susu 1753499414@qq.com
 * @Date: 2022-05-25 08:44:17
 * @LastEditors: stella_susu 1753499414@qq.com
 * @LastEditTime: 2022-05-25 09:14:05
 * @FilePath: \carbon-console-uid:\myProject\changshujulian\website\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:'长数链'
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
