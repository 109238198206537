<!--
 * @Author: stella_susu 1753499414@qq.com
 * @Date: 2022-05-25 08:43:49
 * @LastEditors: stella_susu 1753499414@qq.com
 * @LastEditTime: 2022-05-26 10:54:56
 * @FilePath: \carbon-console-uid:\myProject\changshujulian\website\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>
<script>
export default {
  mounted(){
    window.location.hash = '/'
  }
}
</script>
<style>
@import './style/common.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
img{
  display: block;
}
</style>
